<template>
  <v-layout>
    <v-row align="start" dense justify="center">
      <v-col class="col-auto">
        <v-card class="mt-5" height="250px" width="350px">
        </v-card>
        <h1 class="text-h5 my-15 mx-auto light-green--text">KUWAIT FOOD INDEX</h1>
        <v-row justify="space-around">
          <v-btn color="primary" outlined width="150px" @click="selectLocale('en')">English</v-btn>
          <v-btn color="primary" outlined width="150px" @click="selectLocale('ar')">العربیه</v-btn>
        </v-row>
      </v-col>
    </v-row>
  </v-layout>
</template>

<script>
import { changeLocale } from '@/modules/app/helpers/utils'

export default {
  name: 'KurccLoginIndexPage',
  data () {
    return {
      changeLocale
    }
  },
  methods: {
    selectLocale (locale) {
      this.changeLocale(locale)
      this.$router.push({ name: 'login' })
    }
  }
}
</script>
